import React, { useRef, useState, useEffect } from 'react'
import { Cylinder, Anchor, Group, Ellipse, Hemisphere, Box } from 'react-zdog'
import {
  genRandDecimal,
  randomIntFromInterval,
  randomFromStringList,
  coordinateGenerator,
} from 'components/utils'
import './style.scss'

function FunghiGenerator(quantita = 10, yRotate) {
  const contenitoreFetteFunghi = []
  // const coordinate = [
  //   { x: 10, y: 9 },
  //   { x: 18, y: 35 },
  //   { x: 23, y: 55 },
  //   { x: 31, y: 25 },
  //   { x: 38, y: 42 },
  //   { x: 39, y: 1 },
  //   { x: 42, y: 12 },
  //   { x: 42, y: 66 },
  //   { x: 58, y: 60 },
  //   { x: 64, y: 24 },
  //   { x: 75, y: 10 },
  //   { x: 79, y: 41 },
  //   { x: 81, y: 27 },
  //   { x: 90, y: 31 },
  // ]
  // coordinateGenerator(x0, y0, r, items) {
  const coordinate = coordinateGenerator(55, 40, 61, 15)
    .concat(coordinateGenerator(88, 40, 63, 15))
    .concat(coordinateGenerator(65, 35, 20, 5))
  for (const x of Array(quantita).keys()) {
    for (const c of coordinate) {
      const parentlocalKey = `parent-funghi-${x}-${c.x}-${c.y}`
      const localKey = `funghi-${x}-${c.x}-${c.y}`
      contenitoreFetteFunghi.push(
        <Funghi
          key={parentlocalKey}
          localKey={localKey}
          yRotate={yRotate}
          x={c.x}
          y={c.y}
          colore={c.colore || ''}
        ></Funghi>
      )
    }
  }
  return contenitoreFetteFunghi
}

function Funghi(props) {
  const randomizzaPosizioni = props.randomizzaPosizioni || true
  let randomDecimal = genRandDecimal(0.1, 0.3, 2)

  let randomX = props.x
  let randomY = props.y
  let randomZ = 0.3
  if (randomizzaPosizioni) {
    randomX = props.x + randomIntFromInterval(3, 7)
    randomY = props.y + randomIntFromInterval(3, 7)
    randomZ = randomZ + randomIntFromInterval(1, 20)
  }
  const randomSize = randomIntFromInterval(10, 16)
  const colore = props.colore || randomFromStringList(['#f0ece1']) // #C70039', '#B62514' 8e0000

  return (
    <Group>
      <Box
        width={6}
        height={4}
        depth={1}
        stroke={false}
        color={'#e8d9ba'}
        translate={{ x: 38 + randomX, y: 34 + randomY, z: 3.5 }}
        rotate={{ y: 0.21, x: 3, z: 1.6 }}
      ></Box>
      <Hemisphere
        diameter={12}
        stroke={false}
        color={'#ba8559'}
        backface={colore}
        translate={{ x: 38 + randomX, y: 32 + randomY, z: 10.5 }}
        rotate={{ y: 0, x: 1.8, z: 2.5 }}
      ></Hemisphere>
    </Group>
  )
}

export { Funghi as default, FunghiGenerator }
