import React, { useRef, useState, useEffect } from 'react'
import { Cylinder, Anchor, Group, Ellipse } from 'react-zdog'
import {
  genRandDecimal,
  randomIntFromInterval,
  randomFromStringList,
  coordinateGenerator,
} from 'components/utils'
import './style.scss'

function OliveGenerator(quantita = 10, yRotate) {
  const contenitoreFetteOlive = []
  // const coordinate = [
  //   { x: 9, y: 8 },
  //   { x: 15, y: 41 },
  //   { x: 16, y: 33 },
  //   { x: 25, y: 57 },
  //   { x: 33, y: 22 },
  //   { x: 35, y: 41 },
  //   { x: 43, y: 1 },
  //   { x: 55, y: 1 },
  //   { x: 60, y: 60 },
  //   { x: 62, y: 22 },
  //   { x: 63, y: 33 },
  //   { x: 66, y: 40 },
  //   { x: 76, y: 46 },
  //   { x: 83, y: 44 },
  //   { x: 85, y: 25 },
  //   { x: 95, y: 30 },
  // ]
  // coordinateGenerator(x0, y0, r, items) {
  const coordinate = coordinateGenerator(62, 33, 61, 15)
    .concat(coordinateGenerator(84, 39, 63, 15))
    .concat(coordinateGenerator(65, 35, 20, 5))
  for (const x of Array(quantita).keys()) {
    for (const c of coordinate) {
      const parentlocalKey = `parent-olive-${x}-${c.x}-${c.y}`
      const localKey = `olive-${x}-${c.x}-${c.y}`
      contenitoreFetteOlive.push(
        <Olive
          key={parentlocalKey}
          localKey={localKey}
          yRotate={yRotate}
          x={c.x}
          y={c.y}
          colore={c.colore || ''}
        ></Olive>
      )
    }
  }
  return contenitoreFetteOlive
}

function Olive(props) {
  const randomizzaPosizioni = props.randomizzaPosizioni || true
  let randomDecimal = genRandDecimal(0.1, 0.3, 2)

  let randomX = props.x
  let randomY = props.y
  let randomZ = 0.3
  if (randomizzaPosizioni) {
    randomX = props.x + randomIntFromInterval(3, 7)
    randomY = props.y + randomIntFromInterval(3, 7)
    randomZ = randomZ + randomIntFromInterval(1, 20)
  }
  const randomSize = randomIntFromInterval(10, 16)
  const colore = props.colore || randomFromStringList(['#000', '#000']) // #C70039', '#B62514' 8e0000

  return (
    <Group>
      <Ellipse
        width={4}
        height={4}
        stroke={4}
        color={colore}
        translate={{ x: 40 + randomX, y: 34 + randomY, z: 3.5 }}
        // rotate={{ x: TAU / 18, y: 0.03, z: -2 }}
        // rotate={{ y: -0.5, x: -0.4 }}
        rotate={{
          y: -0.11 + randomDecimal * 5,
          x: 0.8,
          z: 0.5 + randomDecimal,
        }}
      ></Ellipse>
    </Group>
  )
}

export { Olive as default, OliveGenerator }
