import React, { useRef, useState, useEffect } from 'react'
import { Cylinder, Anchor, Group, Ellipse } from 'react-zdog'
import './style.scss'
const TAU = Math.PI * 0.4
import {
  coordinateGenerator,
  randomIntFromInterval,
  randomFromStringList,
} from 'components/utils'

function SalameGenerator(quantita = 10, yRotate) {
  const contenitoreFetteSalame = []
  // let coordinate = [
  //   { x: 25, y: 60 },
  //   { x: 10, y: 16 },
  //   { x: 16, y: 50 },
  //   { x: 25, y: 19 },
  //   { x: 33, y: 52 },
  //   { x: 45, y: 12 },
  //   // { x: 55, y: 76 },
  //   { x: 62, y: 38 },
  //   { x: 66, y: 8 },
  //   { x: 76, y: 50 },
  //   { x: 83, y: 20 },
  //   { x: 43, y: 20 },
  //   { x: 63, y: 30 },
  // ]
  // coordinateGenerator(x0, y0, r, items) {
  let coordinate = coordinateGenerator(55, 40, 48, 12).concat(
    coordinateGenerator(98, 40, 40, 11)
  )

  for (const x of Array(quantita).keys()) {
    for (const c of coordinate) {
      const parentlocalKey = `parent-salame-${x}-${c.x}-${c.y}`
      const localKey = `salame-${x}-${c.x}-${c.y}`
      contenitoreFetteSalame.push(
        <Salame
          key={parentlocalKey}
          localKey={localKey}
          yRotate={yRotate}
          x={c.x}
          y={c.y}
          colore={c.colore || ''}
        ></Salame>
      )
    }
  }
  return contenitoreFetteSalame
}

function Salame(props) {
  const randomizzaPosizioni = props.randomizzaPosizioni || true

  let randomX = props.x
  let randomY = props.y
  let randomZ = 0.3
  if (randomizzaPosizioni) {
    randomX = props.x + randomIntFromInterval(3, 7)
    randomY = props.y + randomIntFromInterval(3, 7)
    randomZ = randomZ + randomIntFromInterval(1, 20)
  }
  const randomSize = randomIntFromInterval(15, 21)
  const colore = props.colore || randomFromStringList(['#B62514', '#B62514']) // #C70039', '#B62514' 8e0000
  const Grasso = () => {
    if (randomSize < 12) {
      // no grasso
      return <React.Fragment></React.Fragment>
    } else if (randomSize < 14) {
      // 1 grasso
      return (
        <Ellipse
          key={`grasso1-${props.localKey}`}
          translate={{ x: 45 + randomX, y: 34 + randomY, z: 3.5 }}
          width={randomIntFromInterval(1, 2)}
          height={1}
          stroke={2}
          color={'#f5efdb'}
        ></Ellipse>
      )
    } else {
      // 2 grasso
      return (
        <React.Fragment>
          <Ellipse
            key={`grasso2-${props.localKey}`}
            translate={{ x: 43 + randomX, y: 34 + randomY, z: 3.5 }}
            width={randomIntFromInterval(0, 2)}
            height={1}
            stroke={2}
            color={'#f5efdb'}
          ></Ellipse>
          <Ellipse
            key={`grasso3-${props.localKey}`}
            translate={{ x: 47 + randomX, y: 31 + randomY, z: 3.5 }}
            width={randomIntFromInterval(1, 2)}
            height={1}
            stroke={2}
            color={'#f5efdb'}
          ></Ellipse>
        </React.Fragment>
      )
    }
  }

  return (
    <Group>
      <Cylinder
        lenght={1}
        diameter={randomSize}
        stroke={true}
        color={colore}
        backface={colore}
        frontface={colore}
        translate={{ x: 45 + randomX, y: 34 + randomY, z: 3.5 }}
        // rotate={{ x: TAU / 18, y: 0.03, z: -2 }}
        // rotate={{ y: -0.5, x: -0.4 }}
        rotate={{ y: -0.11, x: 0.8, z: 0.5 }}
      ></Cylinder>
      <Grasso></Grasso>
    </Group>
    // <Shape
    //   path={[{ y: 0 }, { y: 6 }]}
    //   translate={{ x: 25 + randomX, y: 35 + randomY, z: 3.2 }}
    //   // rotate={{ x: -TAU / 8, y: 3 }}
    //   rotate={{ y: -0.5, x: -0.4 }}
    //   color="#fff"
    //   stroke={5}
    // />
  )
}

export { Salame as default, SalameGenerator }
