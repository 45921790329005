const ingredientiMargherita = {
  mozzarella: true,
  salsa: true,
  salame: false,
  funghi: false,
  olive: false,
  prosciuttocotto: false,
  prosciuttocrudo: false,
  origano: false,
  carciofi: false,
  ananas: false,
}
const ingredientiDiavola = {
  mozzarella: true,
  salsa: true,
  salame: true,
  funghi: false,
  olive: false,
  prosciuttocotto: false,
  prosciuttocrudo: false,
  origano: false,
  carciofi: false,
  ananas: false,
}
const ingredientiCapricciosa = {
  mozzarella: true,
  salsa: true,
  salame: true,
  funghi: true,
  olive: true,
  prosciuttocotto: true,
  prosciuttocrudo: false,
  origano: false,
  carciofi: false,
  ananas: false,
}
const ingredientiBiancaAlCotto = {
  mozzarella: true,
  salsa: false,
  salame: false,
  funghi: false,
  olive: false,
  prosciuttocotto: true,
  prosciuttocrudo: false,
  origano: false,
  carciofi: false,
  ananas: false,
}
const ingredientiFocaccia = {
  mozzarella: false,
  salsa: false,
  salame: false,
  funghi: false,
  olive: false,
  prosciuttocotto: false,
  prosciuttocrudo: false,
  origano: true,
  carciofi: false,
  ananas: false,
}
const ingredientiNapoletana = {
  mozzarella: false,
  salsa: true,
  salame: false,
  funghi: false,
  olive: false,
  prosciuttocotto: false,
  prosciuttocrudo: false,
  origano: true,
  carciofi: false,
  ananas: false,
}

export {
  ingredientiDiavola,
  ingredientiMargherita,
  ingredientiCapricciosa,
  ingredientiBiancaAlCotto,
  ingredientiFocaccia,
  ingredientiNapoletana,
}
