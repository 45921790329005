import { graphql } from 'gatsby'
import React from 'react'
import get from 'lodash/get'

import Meta from 'components/meta'
import Layout from 'components/layout'
import Pizza from 'components/home/Pizza'

import Tooltip from 'react-bootstrap/Tooltip'
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import {
  genRandDecimal,
  randomIntFromInterval,
  randomFromStringList,
  useInterval,
} from 'components/utils'
import {
  ingredientiMargherita,
  ingredientiDiavola,
  ingredientiCapricciosa,
  ingredientiBiancaAlCotto,
  ingredientiFocaccia,
  ingredientiNapoletana,
} from 'components/home/ComposizionePizze'
import './style.scss'

const pizzaUguali = (o1, o2) => {
  return (
    Object.keys(o1).length === Object.keys(o2).length &&
    Object.keys(o1).every(p => o1[p] === o2[p])
  )
}

function Index(props) {
  const { data, location } = props
  const [rotatingFont, setRotatingFont] = React.useState('')
  const [
    rotatingFontDeceleratore,
    setRotatingFontDeceleratore,
  ] = React.useState(0)
  const [pizzaFlavour, setPizzaFlavour] = React.useState('Margherita')
  // margherita, default
  const [indexIngredienti, setIndexIngredienti] = React.useState({
    mozzarella: true,
    salsa: true,
    salame: false,
    funghi: false,
    olive: false,
    prosciuttocotto: false,
    prosciuttocrudo: false,
    origano: false,
    carciofi: false,
    ananas: false,
  })
  const [pizzaEncodedIngredients, setPizzaEncodedIngredients] = React.useState(
    JSON.stringify(indexIngredienti)
  )
  const pizzaChecker = () => {
    if (indexIngredienti) {
      if (pizzaUguali(ingredientiMargherita, indexIngredienti)) {
        setPizzaFlavour('Margherita')
      } else if (pizzaUguali(ingredientiDiavola, indexIngredienti)) {
        setPizzaFlavour('Diavola')
      } else if (pizzaUguali(ingredientiCapricciosa, indexIngredienti)) {
        setPizzaFlavour('Capricciosa')
      } else if (pizzaUguali(ingredientiBiancaAlCotto, indexIngredienti)) {
        setPizzaFlavour('Bianca al cotto')
      } else if (pizzaUguali(ingredientiFocaccia, indexIngredienti)) {
        setPizzaFlavour('Focaccia')
      } else if (pizzaUguali(ingredientiNapoletana, indexIngredienti)) {
        setPizzaFlavour('Napoletana')
      } else {
        setPizzaFlavour('Not sure but go ahead!')
      }
    } else {
      setPizzaFlavour('Not sure but go ahead!')
    }
  }
  const handleSetIndexIngredienti = oggetto => {
    setIndexIngredienti(oggetto)
    setPizzaEncodedIngredients(JSON.stringify(indexIngredienti))
    pizzaChecker()
  }

  React.useEffect(() => {
    pizzaChecker()
  })
  // useInterval(() => {
  //   const stringheFont = ['twin-peaks', 'star-trek', 'evangelion', 'darkplace']

  //   let indiceNuovo = 0
  //   const indiceAttuale = stringheFont.indexOf(rotatingFont)
  //   if (indiceAttuale == stringheFont.length - 1) {
  //     indiceNuovo = 0
  //   } else {
  //     indiceNuovo = indiceAttuale + 1
  //   }
  //   if (rotatingFontDeceleratore < 10) {
  //     // setRotatingFont(stringheFont[indiceNuovo])
  //   } else {
  //     // setRotatingFont('')
  //   }

  //   if (rotatingFontDeceleratore > 25) {
  //     setRotatingFontDeceleratore(0)
  //   } else {
  //     setRotatingFontDeceleratore(rotatingFontDeceleratore + 1)
  //   }
  // }, 333)

  return (
    <Layout location={location}>
      <Meta site={get(data, 'site.meta')} />
      <div className="container home-container">
        {/* <Img fixed={profile} className="rounded-circle" /> */}
        <div className="row flex-column align-enter text-center dev">
          <div className="col">
            {/* <section className="text-center home-section"></section> */}
            <h1 className={`text-primary font-rotate ${rotatingFont}`}>
              Nello{' '}
              {/* <div style={{ margin: '0 auto' }}>
                <RotatingN
                  extraCSS={{
                    width: '40px',
                    height: '40px',
                    margin: '0 auto',
                  }}
                />
              </div> */}
            </h1>

            <p className={`lead text-secondary font-rotate ${rotatingFont}`}>
              <span>front-end dev, Milan</span>
            </p>
          </div>
        </div>
        <div className="row message-row">
          <div className="col">
            <div className="message-container-inner">
              {/* <p className="lead">
              <b>Welcome!</b> Feel free to wander around and leave a message.{' '}
              <br />
            </p> */}
              {/* <p>
              <i>Craft</i> and send along your very own favourite flavour of{' '}
              <span className="pizza-text-craft">
                <span className="pizza-inner-text">pizza</span>
                <span></span>
                <span></span>
                <span></span>
              </span>
            </p> */}
            </div>
          </div>
        </div>
        <div className="row justify-content-center form-container-row mb-4">
          <div className="col">
            <div className="m-b-1">
              <b>Ciao</b>! Welcome, get in touch.
            </div>
            <span className="font-size-xs d-inline">
              Also, while you're at it, have some fun <b>crafting</b> your
              favourite type of pizza: it's 3D and the ingredients are randomly
              generated each time you add one of them!
              {/*  Once you hit "Send", I'll
              get your chosen topping variation right along with your message. */}
            </span>
          </div>
        </div>
        <div className="row justify-content-center form-container-row">
          {/* <div className="col col-lg-4">
            Craft your favourite pizza flavour by the side (3D and
            procedurally generated!) and I'll get it along with your message.
            🍕
          </div> */}
          <div className="col">
            <Pizza
              setIndexIngredienti={handleSetIndexIngredienti}
              extraCSS={{ width: '260px', height: '220px' }}
            ></Pizza>

            <div className="text-center pizza-flavour-container">
              {/* <span className="text-center d-inline-block w-100 text-secondary font-size-xs">
                  You're baking what looks like a delicious...
                </span> */}
              {/* <input
                  type="text"
                  name="pizzaflavour"
                  placeholder={`${pizzaFlavour}`}
                  disabled
                /> */}

              {/* <OverlayTrigger
                  placement={'top'}
                  overlay={
                    <Tooltip id="tooltip-disabled" className="font-size-xs">
                      I'm detecting that you're baking what looks like a
                      delicious...
                    </Tooltip>
                  }
                >
                  <span className="d-inline-block align-question-mark">
                    <Button size="sm" style={{ pointerEvents: 'none' }}>
                      ?
                    </Button>
                  </span>
                </OverlayTrigger> */}
            </div>
          </div>
        </div>
        {/* <div className="row justify-content-center form-container-row">
          <div className="col">
            <div className="form-container">
              <form
                name="contact"
                method="POST"
                data-netlify="true"
                data-netlify-honeypot="b-field"
              >
                <input type="hidden" name="form-name" value="contact" />
                <input
                  type="hidden"
                  name="pizza-encoded-ingredients"
                  value={`${pizzaEncodedIngredients}`}
                />
                <input
                  type="hidden"
                  name="pizzaflavour-to-send"
                  placeholder={`${pizzaFlavour}`}
                />
                <p className="hidden" style={{ display: 'none' }}>
                  <label>
                    your name: <input name="b-field" />{' '}
                  </label>
                </p>
                <div className="pizza-flavour-wrapper">
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">
                        <OverlayTrigger
                          placement={'top'}
                          overlay={
                            <Tooltip
                              id="tooltip-disabled"
                              className="font-size-xs"
                            >
                              I'm detecting that you're baking what looks like a
                              delicious...
                            </Tooltip>
                          }
                        >
                          <span className="d-inline-block align-question-mark">
                            <Button size="sm" style={{ pointerEvents: 'none' }}>
                              ?
                            </Button>
                          </span>
                        </OverlayTrigger>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      disabled
                      aria-label="Pizza Flavour"
                      name="pizzaflavour"
                      placeholder={`${pizzaFlavour}`}
                    />
                  </InputGroup>
                </div>
                <div>
                  <InputGroup className="mb-3">
                    <FormControl
                      required
                      placeholder="Your name"
                      aria-label="Your name"
                      aria-describedby="basic-addon1"
                      name="name"
                    />
                  </InputGroup>
                </div>
                <div>
                  <InputGroup className="mb-3">
                    <FormControl
                      required
                      placeholder="Your e-mail"
                      aria-label="Your e-mail"
                      aria-describedby="basic-addon1"
                      name="email"
                    />
                  </InputGroup>
                </div>
                <div>
                  <label htmlFor="message">Message:</label>
                  <InputGroup>
                    <FormControl
                      required
                      as="textarea"
                      aria-label="Message"
                      name="message"
                    />
                  </InputGroup>
                </div>
                <div>
                  <Button type="submit" variant="primary">
                    Send
                  </Button>{' '}
                </div>
              </form>
            </div>
          </div>
        </div> */}
      </div>
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query IndexQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
      }
    }
    remark: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      posts: edges {
        post: node {
          html
          frontmatter {
            layout
            title
            path
            category
            tags
            description
            date(formatString: "DD/MM/YYYY")
            disabled
            # image {
            #   childImageSharp {
            #     fluid(maxWidth: 500) {
            #       ...GatsbyImageSharpFluid
            #     }
            #   }
            # }
          }
        }
      }
    }
  }
`
