import React, { useRef, useState, useEffect } from 'react'
import { Group, Box } from 'react-zdog'
import {
  genRandDecimal,
  randomIntFromInterval,
  randomFromStringList,
  coordinateGenerator,
} from 'components/utils'
import './style.scss'

function ProsciuttoCottoGenerator(quantita = 10, yRotate) {
  const contenitoreFetteProsciuttoCotto = []
  // const coordinate = [
  //   { x: 14, y: 8 },
  //   { x: 4, y: 33 },
  //   { x: 15, y: 57 },
  //   { x: 33, y: 32 },
  //   { x: 13, y: 22 },
  //   { x: 38, y: 1 },
  //   { x: 50, y: 24 },
  //   { x: 55, y: 17 },
  //   { x: 55, y: 55 },
  //   { x: 55, y: 45 },
  //   { x: 59, y: 5 },
  //   { x: 58, y: 24 },
  //   { x: 75, y: 19 },
  //   { x: 76, y: 51 },
  //   { x: 88, y: 49 },
  //   { x: 88, y: 24 },
  //   { x: 88, y: 12 },
  //   { x: 78, y: 8 },
  //   { x: 38, y: 51 },
  // ]
  // coordinateGenerator(x0, y0, r, items) {
  const coordinate = coordinateGenerator(50, 40, 62, 15)
    .concat(coordinateGenerator(65, 35, 40, 10))
    .concat(coordinateGenerator(105, 40, 51, 12))

  for (const x of Array(quantita).keys()) {
    for (const c of coordinate) {
      const parentlocalKey = `parent-prosciuttocotto-${x}-${c.x}-${c.y}`
      const localKey = `prosciuttocotto-${x}-${c.x}-${c.y}`
      contenitoreFetteProsciuttoCotto.push(
        <ProsciuttoCotto
          key={parentlocalKey}
          localKey={localKey}
          yRotate={yRotate}
          x={c.x}
          y={c.y}
          colore={c.colore || ''}
        ></ProsciuttoCotto>
      )
    }
  }
  return contenitoreFetteProsciuttoCotto
}

function ProsciuttoCotto(props) {
  const randomizzaPosizioni = props.randomizzaPosizioni || true
  let randomDecimal = genRandDecimal(0.1, 0.3, 2)

  let randomX = props.x
  let randomY = props.y
  let randomZ = 0.3
  if (randomizzaPosizioni) {
    randomX = props.x + randomIntFromInterval(3, 7)
    randomY = props.y + randomIntFromInterval(3, 7)
    randomZ = randomZ + randomIntFromInterval(1, 20)
  }
  const randomSize = randomIntFromInterval(10, 16)
  const colore =
    props.colore ||
    randomFromStringList(['pink', '#fbb5af', '#fcc1bc', '#faa8a1']) // #C70039', '#B62514' 8e0000

  return (
    <Group>
      <Box
        width={7}
        height={7}
        depth={4}
        stroke={false}
        color={colore}
        translate={{ x: 40 + randomX, y: 34 + randomY, z: 3.5 }}
        rotate={{
          y: -0.11 + randomDecimal * 5,
          x: 0.8,
          z: 0.5 + randomDecimal,
        }}
      ></Box>
    </Group>
  )
}

export { ProsciuttoCotto as default, ProsciuttoCottoGenerator }
