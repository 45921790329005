import React, { useState, useEffect, useRef } from 'react'
const TAU = Math.PI * 0.4

function genRandDecimal(min, max, decimalPlaces) {
  var rand = Math.random() * (max - min) + min
  var power = Math.pow(10, decimalPlaces)
  return Math.floor(rand * power) / power
}
function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}
function randomFromStringList(stringList) {
  const randomPosition = Math.floor(Math.random() * stringList.length)
  return stringList[randomPosition]
}
function useInterval(callback, delay) {
  const savedCallback = React.useRef()

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  React.useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}
function coordinateGenerator(x0, y0, r, items) {
  const coordinate = []
  for (let i = 0; i < items; i++) {
    let x = x0 + r * Math.cos((2 * Math.PI * i) / items)
    let y = y0 + r * Math.sin((2 * Math.PI * i) / items)
    coordinate.push({
      x: x + randomIntFromInterval(5, 10),
      y: y + randomIntFromInterval(5, 10),
    })
  }
  return coordinate
}
export {
  genRandDecimal,
  randomIntFromInterval,
  randomFromStringList,
  useInterval,
  coordinateGenerator,
  TAU,
}
