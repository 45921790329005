import React, { useRef, useState, useEffect } from 'react'
import { Cylinder, Anchor, Group, Ellipse, Shape } from 'react-zdog'
import './style.scss'
const TAU = Math.PI * 0.4

function SalsaGenerator(yRotate) {
  return <Salsa key="salsa-generator-key" yRotate={yRotate}></Salsa>
}

function Salsa(props) {
  const salsaRef = React.useRef()
  return (
    <Group>
      <Cylinder
        ref={salsaRef}
        diameter={210}
        stroke={true}
        color={'#df3636'}
        backface={'#df3636'}
        translate={{ x: 130, y: 90 }}
        rotate={{ y: -0.11, x: 0.8, z: 0.52 }}
      ></Cylinder>
      {/* <Shape
        path={[
          { x: 20, y: -60 },
          {
            bezier: [
              { x: 120, y: -60 },
              { x: 20, y: 60 },
              { x: -160, y: 90 },
            ],
          },
          { x: 20, y: -60 },
        ]}
        closed={false}
        stroke={20}
        color={'#636'}
      ></Shape> */}
    </Group>
    // ctx.moveTo(164 + xoff, 156 + yoff);
    // ctx.bezierCurveTo(181 + xoff, 43 + yoff, 365 + xoff, 49 + yoff, 380 + xoff, 132 + yoff);
    // ctx.bezierCurveTo(396 + xoff, 219 + yoff, 165 + xoff, 241 + yoff, 164 + xoff, 160 + yoff);
  )
}

export { Salsa as default, SalsaGenerator }
