import React, { useRef, useState, useEffect } from 'react'
import { Group, RoundedRect, Box } from 'react-zdog'
import {
  coordinateGenerator,
  randomIntFromInterval,
  randomFromStringList,
} from 'components/utils'
import './style.scss'

function MozzarellaGenerator(quantita = 10, yRotate) {
  const contenitoreFiocchiMozzarella = []
  // let coordinate = [
  //   { x: 2 + 15, y: 25 },
  //   { x: 5 + 15, y: 32 },
  //   { x: 8 + 15, y: 5 },
  //   { x: 16 + 15, y: 60 },
  //   { x: 19 + 15, y: 3 },
  //   { x: 25 + 15, y: 16 },
  //   { x: 25 + 15, y: 60 },
  //   { x: 30 + 25, y: 5 },
  //   { x: 30 + 25, y: 25 },
  //   { x: 33 + 25, y: 52 },
  //   { x: 42 + 25, y: 3 },
  //   { x: 45 + 25, y: 12 },
  //   { x: 46 + 25, y: 30 },
  //   { x: 52 + 25, y: 45 },
  //   { x: 52 + 25, y: 13 },
  //   { x: 55 + 25, y: -4 },
  //   { x: 59 + 25, y: 60 },
  //   { x: 60 + 25, y: 30 },
  //   { x: 62 + 25, y: 38 },
  //   { x: 65 + 25, y: 1 },
  //   { x: 66 + 25, y: 8 },
  //   { x: 76 + 25, y: 50 },
  //   { x: 83 + 25, y: 20 },
  //   { x: 84 + 25, y: 33 },
  // ]
  // coordinate = []
  // coordinateGenerator(x0, y0, r, items) {
  let coordinate = coordinateGenerator(55, 40, 61, 10).concat(
    coordinateGenerator(88, 40, 58, 10)
  )

  for (const x of Array(quantita).keys()) {
    for (const c of coordinate) {
      const parentlocalKey = `parent-mozzarella-${x}-${c.x}-${c.y}`
      const localKey = `mozzarella-${x}-${c.x}-${c.y}`
      let _m = (
        <Mozzarella
          key={parentlocalKey}
          localKey={localKey}
          yRotate={yRotate}
          x={c.x}
          y={c.y}
          colore={c.colore || ''}
        ></Mozzarella>
      )

      contenitoreFiocchiMozzarella.push(_m)
    }
  }
  return contenitoreFiocchiMozzarella
}

function Mozzarella(props) {
  const randomizzaPosizioni = props.randomizzaPosizioni || true
  const mozzarellaRef = React.useRef()

  let randomX = props.x
  let randomY = props.y
  let randomZ = 0.3
  if (randomizzaPosizioni) {
    randomX = props.x + randomIntFromInterval(3, 7)
    randomY = props.y + randomIntFromInterval(3, 7)
    randomZ = randomZ + randomIntFromInterval(1, 20)
  }
  const randomSizeBox = randomIntFromInterval(15, 18)
  const randomSizeRoundedRect = randomIntFromInterval(22, 26)
  const colore = props.colore || randomFromStringList(['#fff', '#F1EABC'])

  // useRender(() => {
  //   if (props.yRotate) {
  //     mozzarellaRef.current.rotate.y = props.yRotate
  //   }
  // })

  return (
    <Group key="mozzarella-group">
      (
      {randomIntFromInterval(0, 1) ? (
        <Box
          key={props.localKey}
          ref={mozzarellaRef}
          width={randomSizeBox}
          height={randomSizeBox}
          depth={2}
          color={colore}
          translate={{ x: 45 + randomX, y: 34 + randomY, z: 0.5 }}
          // rotate={{ y: -0.5, x: -0.4, z: randomZ }}
          rotate={{ y: -0.11, x: 0.8, z: 0.9 }}
        ></Box>
      ) : (
        <RoundedRect
          key={props.localKey}
          ref={mozzarellaRef}
          width={randomSizeRoundedRect}
          height={randomSizeRoundedRect}
          depth={2}
          color={colore}
          translate={{ x: 45 + randomX, y: 34 + randomY, z: 0.5 }}
          // rotate={{ y: -0.5, x: -0.4, z: randomZ }}
          rotate={{ y: -0.11, x: 0.8, z: 0.9 }}
          cornerRadius={randomIntFromInterval(12, 30)}
          fill={true}
        ></RoundedRect>
      )}
      )
    </Group>
    // <Cylinder
    //   lenght={1}
    //   diameter={randomSize}
    //   stroke={false}
    //   color={colore}
    //   backface={colore}
    //   translate={{ x: 25 + randomX, y: 34 + randomY, z: 0.3 }}
    //   // rotate={{ x: TAU / 18, y: 0.03, z: -2 }}
    //   rotate={{ y: -0.5, x: -0.4 }}
    // ></Cylinder>

    // <Shape
    //   path={[{ y: 0 }, { y: 6 }]}
    //   translate={{ x: 25 + randomX, y: 35 + randomY, z: 3.2 }}
    //   // rotate={{ x: -TAU / 8, y: 3 }}
    //   rotate={{ y: -0.5, x: -0.4 }}
    //   color="#fff"
    //   stroke={5}
    // />
  )
}

export { Mozzarella as default, MozzarellaGenerator }
