import React, { useRef, useState, useEffect } from 'react'
import { Illustration, Anchor, Group, Cylinder, Shape } from 'react-zdog'
import { a, useSpring } from 'react-spring/zdog'

import './style.scss'
import { MozzarellaGenerator } from './Mozzarella'
import { SalameGenerator } from './Salame'
import { SalsaGenerator } from './Salsa'
import { OliveGenerator } from './Olive'
import { FunghiGenerator } from './Funghi'
import { ProsciuttoCottoGenerator } from './ProsciuttoCotto'
import { OriganoGenerator } from './Origano'

const PizzaContext = React.createContext()

function Ingredienti(props) {
  return (
    <React.Fragment key="fragment-ingredienti-lista-ingredienti">
      {props.listaIngredienti}
    </React.Fragment>
  )
}
function Pizza(props) {
  // useRender(() => {
  //   if (pizzaRef.current && props.rotating) {
  //     // pizzaRef.current.rotate.y = (y += 0.1) / TAU
  //     // pizzaRef.current.rotate.z = (z += 0.1) / (TAU * 3)
  //   }
  // }, [props.rotating])

  return (
    <div>
      <Anchor>
        <Dough></Dough>
      </Anchor>
      <Anchor>
        <Ingredienti
          key="ingredienti-lista-ingredienti"
          listaIngredienti={props.listaIngredienti}
        ></Ingredienti>
      </Anchor>
    </div>
  )
}
function Dough(props) {
  const pizzaContext = React.useContext(PizzaContext)
  let { doughRef } = pizzaContext
  let { crostaRef } = pizzaContext
  let { yRotate, setYRotate } = pizzaContext

  const [up, setUp] = useState(true)

  // useEffect(() => {
  //   setInterval(() => setUp(previous => !previous), 450)
  // }, [])

  const { rotation, color, size } = useSpring({
    size: up ? 1.2 : 0.2,
    color: up ? '#EA0' : 'tomato',
    rotation: up ? 0 : Math.PI,
  })

  // useRender(() => {
  //   let _yRotate = Math.cos((yRotate += 0.02) / 0.3) / 10
  //   setYRotate(_yRotate)
  //   // doughRef.current.rotate.y = _yRotate
  //   // crostaRef.current.rotate.y = _yRotate
  // })

  // useRender(() => {
  //   if (doughRef.current) {
  //     // doughRef.current.rotate.y = Math.cos((t += 0.1) / TAU)
  //   }
  // })

  return (
    <React.Fragment>
      <Group>
        <Cylinder
          key="pizzaDough"
          // crosta
          ref={crostaRef}
          diameter={240}
          stroke={true}
          color={'#C39D76'}
          backface={'#C39D76'} // F1B42F dba24a E5BF80 C39D76
          translate={{ x: 130, y: 92 }}
          rotate={{ y: -0.11, x: 0.8, z: 0.5 }}
          // rotate={rotation.interpolate(r => {
          //   // x: TAU / 18 + -r / 4,
          //   // 1,25 / 18 + -r
          //   return { x: TAU / 18 + -r / 12, y: -0.11, z: 0.5 }
          // })}
        ></Cylinder>
        {/* <Group>
          <Ellipse
            // bruciacchiature
            width={20}
            height={20}
            stroke={20}
            color={'#6b3b00'}
            translate={{ x: 133, y: 62 }}
            rotate={{ y: -0.11, x: 0.8 }}
          />
          <Ellipse
            // bruciacchiature
            width={20}
            height={20}
            stroke={20}
            color={'#6b3b00'}
            translate={{ x: 133, y: 22 }}
            rotate={{ y: -0.11, x: 0.8 }}
          />
        </Group> */}
        <Cylinder
          key="pizzaDough2"
          // pasta in basso
          ref={doughRef}
          diameter={225}
          stroke={true}
          color={'#E5BF80'}
          backface={'#E5BF80'}
          translate={{ x: 130, y: 90 }}
          rotate={{ y: -0.11, x: 0.8, z: 0.5 }}
        ></Cylinder>

        {/* <Shape
          // olio wip
          path={[
            { x: 48 + 20, y: 40 }, // start
            {
              arc: [
                { x: 3 + 20, y: 72 }, // corner
                { x: 46 + 20, y: 94 }, // end point
              ],
            },
            {
              arc: [
                { x: 66 + 20, y: 104 }, // corner
                { x: 86 + 20, y: 85 }, // end point
              ],
            },
          ]}
          closed={false}
          stroke={2}
          rotate={{ y: -0.11, x: 0, z: 0 }}
          color={'#87987d'}
          // color={'#0e8110'}
        ></Shape> */}
      </Group>
    </React.Fragment>
  )
}

function exportPizza(props) {
  // valori in trickle down dati a context per gestione inner dei current da passare interni
  // yRotate contiene valore di rotazione pizza passato poi e consumato anche dagli ingredienti
  // il valore è aggiornato da useRender in Dough
  const [yRotate, setYRotate] = useState(0)

  // Dough
  const doughRef = useRef()
  const crostaRef = useRef()
  // Pizza
  const anchorRef = useRef()

  // workaround per force re-rendering con componenti funzionali
  const [, updateState] = React.useState()
  const forceUpdate = React.useCallback(() => updateState({}), [])
  // lista oggetti da generator
  const [listaIngredienti, setListaIngredienti] = useState([])
  // stato di attività degli ingredienti tramite interazione con UI
  const [statoIngredienti, setStatoIngredienti] = useState({
    mozzarella: true,
    salsa: true,
    salame: false,
    funghi: false,
    olive: false,
    prosciuttocotto: false,
    prosciuttocrudo: false,
    origano: false,
    carciofi: false,
    ananas: false,
  })

  // fiocchiMozzarella
  const [oggetto3DFiocchiMozzarella, setOggetto3DFiocchiMozzarella] = useState(
    false
  )
  const [oggetto3DFetteSalame, setOggetto3DFetteSalame] = useState(false)
  const [oggetto3DSalsa, setOggetto3DSalsa] = useState(false)
  const [oggetto3DOlive, setOggetto3DOlive] = useState(false)
  const [oggetto3DFunghi, setOggetto3DFunghi] = useState(false)
  const [oggetto3DOrigano, setOggetto3DOrigano] = useState(false)
  const [oggetto3DProsciuttoCotto, setOggetto3DProsciuttoCotto] = useState(
    false
  )

  const contextPizzaObject = {
    doughRef: doughRef,
    crostaRef: crostaRef,
    anchorRef: anchorRef,
    yRotate: yRotate,
    setYRotate: setYRotate,
    statoIngredienti: statoIngredienti,
  }
  useEffect(() => {
    console.log('using effect')
  })
  // inizializzo ingredienti
  useEffect(() => {
    let fiocchiMozzarella = MozzarellaGenerator(1, yRotate)
    let salsa = SalsaGenerator(1, yRotate)
    let fetteSalame = SalameGenerator(1, yRotate)
    let olive = OliveGenerator(1, yRotate)
    let funghi = FunghiGenerator(1, yRotate)
    let prosciuttocotto = ProsciuttoCottoGenerator(1, yRotate)
    let origano = OriganoGenerator(1, yRotate)

    setOggetto3DFiocchiMozzarella(fiocchiMozzarella)
    setOggetto3DSalsa(salsa)
    setOggetto3DFetteSalame(fetteSalame)
    setOggetto3DOlive(olive)
    setOggetto3DFunghi(funghi)
    setOggetto3DProsciuttoCotto(prosciuttocotto)
    setOggetto3DOrigano(origano)
    // setListaIngredienti([salsa, fiocchiMozzarella])
    setListaIngredienti([
      fiocchiMozzarella,
      salsa,
      // olive,
      // prosciuttocotto,
      // fetteSalame,
      // funghi,
      // origano,
    ])
    // return () => {}
  }, [])
  // gestione toggle visualizza/rimuovi da bottoni
  const toggleIngrediente = stringaIngrediente => {
    if (stringaIngrediente in statoIngredienti) {
      // se l'ingrediente è false lo attivo e viceversa, aggiorno poi stato
      statoIngredienti[stringaIngrediente] = !statoIngredienti[
        stringaIngrediente
      ]
      setStatoIngredienti(statoIngredienti)

      props.setIndexIngredienti(statoIngredienti)

      const newListaIngredienti = []
      for (const [ingrediente, attivo] of Object.entries(statoIngredienti)) {
        if (ingrediente == 'mozzarella' && attivo) {
          newListaIngredienti.push(oggetto3DFiocchiMozzarella)
        }
        if (ingrediente == 'salame' && attivo) {
          newListaIngredienti.push(oggetto3DFetteSalame)
        }
        if (ingrediente == 'salsa' && attivo) {
          newListaIngredienti.push(oggetto3DSalsa)
        }
        if (ingrediente == 'olive' && attivo) {
          newListaIngredienti.push(oggetto3DOlive)
        }
        if (ingrediente == 'funghi' && attivo) {
          newListaIngredienti.push(oggetto3DFunghi)
        }
        if (ingrediente == 'prosciuttocotto' && attivo) {
          newListaIngredienti.push(oggetto3DProsciuttoCotto)
        }
        if (ingrediente == 'origano' && attivo) {
          newListaIngredienti.push(oggetto3DOrigano)
        }
      }

      setListaIngredienti(newListaIngredienti)
    }
    // setTimeout(() => {
    //   console.log('forceUpdate outer')
    //   forceUpdate()
    // }, 200)
  }
  return (
    <PizzaContext.Provider value={contextPizzaObject}>
      <div className="row flex-row pizza-component-parent-wrapper">
        <div className="col-12 col-parent-wrapper-1 col-md-3">
          <div className="ingredienti-wrapper left-column">
            {' '}
            <button
              type="button"
              className={`btn ${
                statoIngredienti['salsa']
                  ? 'btn-primary'
                  : 'btn-outline-primary'
              }`}
              onClick={() => toggleIngrediente('salsa')}
            >
              Sauce
            </button>
            <button
              type="button"
              className={`btn ${
                statoIngredienti['mozzarella']
                  ? 'btn-primary'
                  : 'btn-outline-primary'
              }`}
              onClick={() => toggleIngrediente('mozzarella')}
            >
              Mozzarella
            </button>
            <button
              type="button"
              className={`btn ${
                statoIngredienti['salame']
                  ? 'btn-primary'
                  : 'btn-outline-primary'
              }`}
              onClick={() => toggleIngrediente('salame')}
            >
              Salame
            </button>
            <button
              type="button"
              className={`btn ${
                statoIngredienti['olive']
                  ? 'btn-primary'
                  : 'btn-outline-primary'
              }`}
              onClick={() => toggleIngrediente('olive')}
            >
              Olives
            </button>
          </div>
        </div>
        <div className="col-12 col-parent-wrapper-2 col-md-6">
          <div className="pizza-wrapper" style={props.extraCSS}>
            <Illustration zoom={0}>
              <Pizza listaIngredienti={listaIngredienti}></Pizza>
            </Illustration>
          </div>
        </div>
        <div className="col-12 col-parent-wrapper-3 col-md-3">
          <div className="ingredienti-wrapper right-column">
            {/* <div className="stocking-up">
            <span>Nello's pizzeria is stocking up on more ingredients!</span>
          </div> */}
            <button
              type="button"
              className={`btn ${
                statoIngredienti['funghi']
                  ? 'btn-primary'
                  : 'btn-outline-primary'
              }`}
              onClick={() => toggleIngrediente('funghi')}
            >
              Mushrooms
            </button>
            {/* <button
                type="button"
                className={`btn ${
                  statoIngredienti['carciofi']
                    ? 'btn-primary'
                    : 'btn-outline-primary'
                }`}
                onClick={() => toggleIngrediente('carciofi')}
              >
                Carciofi
              </button> */}
            <button
              type="button"
              className={`btn ${
                statoIngredienti['prosciuttocotto']
                  ? 'btn-primary'
                  : 'btn-outline-primary'
              }`}
              onClick={() => toggleIngrediente('prosciuttocotto')}
            >
              Ham
            </button>
            {/* <button
                type="button"
                className={`btn ${
                  statoIngredienti['prosciuttocrudo']
                    ? 'btn-primary'
                    : 'btn-outline-primary'
                }`}
                onClick={() => toggleIngrediente('prosciuttocrudo')}
              >
                Crudo
              </button> */}
            <button
              type="button"
              className={`btn ${
                statoIngredienti['origano']
                  ? 'btn-primary'
                  : 'btn-outline-primary'
              }`}
              onClick={() => toggleIngrediente('origano')}
            >
              Origano
            </button>
          </div>
        </div>
      </div>
    </PizzaContext.Provider>
  )
}

export { exportPizza as default, PizzaContext }
