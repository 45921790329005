import React, { useRef, useState, useEffect } from 'react'
import { Cylinder, Anchor, Group, Ellipse, Hemisphere, Box } from 'react-zdog'
import {
  coordinateGenerator,
  genRandDecimal,
  randomIntFromInterval,
  randomFromStringList,
} from 'components/utils'
import './style.scss'

function OriganoGenerator(quantita = 10, yRotate) {
  const contenitoreOrigano = []
  // coordinateGenerator(x0, y0, r, items) {
  let coordinate = coordinateGenerator(55, 40, 61, 8)
    .concat(coordinateGenerator(88, 40, 58, 8))
    .concat(coordinateGenerator(65, 50, 58, 8))
    .concat(coordinateGenerator(105, 50, 58, 8))
    .concat(coordinateGenerator(105, 50, 38, 8))
  for (const x of Array(quantita).keys()) {
    for (const c of coordinate) {
      const localKey = `origano-${x}-${c.x}-${c.y}`
      contenitoreOrigano.push(
        <Origano
          localKey={localKey}
          yRotate={yRotate}
          x={c.x}
          y={c.y}
          colore={c.colore || ''}
        ></Origano>
      )
    }
  }
  return contenitoreOrigano
}

function Origano(props) {
  const randomizzaPosizioni = props.randomizzaPosizioni || true
  let randomDecimal = genRandDecimal(0.1, 0.3, 2)

  let randomX = props.x
  let randomY = props.y
  let randomZ = 0.3
  if (randomizzaPosizioni) {
    randomX = props.x + randomIntFromInterval(3, 7)
    randomY = props.y + randomIntFromInterval(3, 7)
    randomZ = randomZ + randomIntFromInterval(1, 20)
  }
  const randomSize = randomIntFromInterval(10, 16)
  const colore =
    props.colore ||
    randomFromStringList(['#93956e', '#7b8118', '#616743', '#907d42']) // #C70039', '#B62514' 8e0000

  return (
    <Group>
      <Box
        width={2}
        height={1}
        depth={2}
        stroke={false}
        color={colore}
        translate={{ x: 40 + randomX, y: 34 + randomY, z: 3.5 }}
        rotate={{ y: -0.11, x: 0.8, z: 0.5 }}
      ></Box>
    </Group>
  )
}

export { Origano as default, OriganoGenerator }
